import styled from "styled-components";
import { Box } from "grommet";
import theme from "../../config/theme";

export const ImageBox = styled(Box)`
  position: relative;
  margin-left: auto;
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth};` : "")}

  &::before {
    position: absolute;
    top: 55px;
    left: -40px;
    background-color: ${theme.colors.dark2};
    width: 494px;
    height: 374px;
    z-index: 1;
  }

  img {
    box-shadow: 0 4px 40px 5px rgba(0, 0, 0, 0.6);
    max-width: ${props => props.maxWidth};
    min-width: 0;
    width: 100%;
    height: auto;
    z-index: 2;
  }

  @media (min-width: ${theme.breakpoints.xlarge}) {
    &::before {
      content: "";
    }

    min-width: 494px;
    min-height: 374px;
  }
`;
