import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, Paragraph } from "grommet";
import { StaticImage } from "gatsby-plugin-image";
import { Container } from "../elements/Layout";
import SEO from "../components/SEO";
import { Route, Title } from "../elements/Text";
import divider from "../images/servicesbg.jpg";
import { LinkButton } from "../elements/Button";
import { ImageBox } from "../elements/ImageBox";
import theme from "../../config/theme";
import { CONTACT_PAGE } from "../../config/routes";
import LatestWorksSection from "../components/LatestWorksSection";
import { FadeInLeft, FadeInRight } from "../elements/Animations";

const ServicesPage = styled.div`
  .hero-section {
    padding: 40px 0;
    margin-bottom: 60px;

    .image-wrapper {
      margin-bottom: 40px;
    }
  }

  .slider-container {
    padding: 0;

    .slick-dots {
      bottom: -55px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .websites {
    .title {
      text-align: center;
      max-width: none;
      margin-bottom: 70px;
    }

    .image-wrapper img {
      max-width: 581px;
    }
  }

  .service-title {
    margin-bottom: 20px;
    font-size: 22px;
    color: ${theme.colors.body};
    font-weight: bold;
  }

  .apps {
    .image-wrapper img {
      max-width: 436px;
    }
  }

  .webapps,
  .ecommerce {
    .image-wrapper img {
      max-width: 560px;
    }
  }

  .crm {
    .image-wrapper img {
      max-width: 552px;
    }
  }

  .service-item {
    margin-bottom: 60px;

    .image-wrapper {
      flex-basis: 100%;
      margin-bottom: 40px;
    }
  }

  .row {
    flex-direction: column;
  }

  .gap {
    width: 40px;
  }

  .cover {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    background: linear-gradient(to bottom, rgba(33, 33, 54, 1), transparent), url(${divider}) top no-repeat;
    background-size: cover;
    margin-bottom: 60px;
    padding: 0 15px;

    .text {
      font-size: 28px;
      line-height: 1.5;
      text-align: center;
      max-width: 926px;
    }

    a {
      color: ${theme.colors.brand};
      font-weight: bold;
      white-space: nowrap;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .hero-title {
    align-self: center;
  }

  .d-none {
    display: none;
  }

  .responsive-justify {
    justify-content: center;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .hero-section {
      padding: 80px 0;
      margin-bottom: 110px;

      p {
        max-width: 580px;
      }
    }

    .hero-title {
      align-self: flex-start;
    }

    .row {
      flex-direction: row;
    }

    .service-title {
      font-size: 26px;
    }

    .service-item {
      margin-bottom: 140px;

      &.reverse {
        .row {
          flex-direction: row-reverse;
        }
      }
    }

    .cover {
      margin-bottom: 140px;

      .text {
        font-size: 36px;
      }
    }

    .d-lg-block {
      display: flex;
    }

    .d-lg-none {
      display: none;
    }

    .responsive-justify {
      justify-content: flex-start;
    }
  }
`;

const Services = ({ location }) => {
  useEffect(() => {
    const { hash } = window.location;
    if (hash !== "") {
      const target = document.getElementById(hash.replace("#", ""));
      if (target) {
        window.scrollTo({ top: target.offsetTop - 100, behavior: "smooth" });
      }
    }
  }, []);

  return (
    <ServicesPage>
      <SEO title="Services" location={location} />
      <section className="hero-section">
        <Container>
          <Box direction="row-responsive" align="center" basis="full">
            <FadeInLeft>
              <Box>
                <Title level="1" className="hero-title" decorator margin={{ bottom: "medium" }}>
                  Services
                </Title>

                <Box className="d-lg-none image-wrapper">
                  <StaticImage src="../images/services.jpg" alt="services" placeholder="none" quality={90} />
                </Box>

                <Paragraph margin={{ vertical: "large" }}>
                  We are offering a wide variety of services on a professional level with an individual approach to each
                  customer. Looking for someone to create a simple one-page website to show your portfolio or a complex
                  CRM system for a big corporation? Titan Design is the right choice! We create fast, modern, and
                  appealing solutions that really work.
                </Paragraph>

                <Box direction="row" flex={false} className="responsive-justify">
                  <LinkButton route={CONTACT_PAGE}>Contact us</LinkButton>
                </Box>
              </Box>
            </FadeInLeft>

            <div className="gap d-none d-lg-block" />

            <ImageBox className="d-none d-lg-block" align="end" maxWidth="600px">
              <StaticImage src="../images/services.jpg" alt="services" placeholder="none" quality={90} />
            </ImageBox>
          </Box>
        </Container>
      </section>

      <section className="websites service-item" id="websites">
        <Container>
          <Title level="2" className="title" color="title" margin={{ bottom: "medium" }}>
            What we do
          </Title>

          <Box direction="row" className="row" gap="xlarge" basis="full">
            <Box className="image-wrapper" width="full">
              <StaticImage src="../images/services/cm.png" alt="websites" placeholder="none" quality={90} />
            </Box>

            <Box width="full">
              <FadeInRight>
                <h3 className="service-title">Websites</h3>
                <Paragraph margin={{ vertical: "large" }}>
                  Bill Gates once said, "If your company doesn't have a website, you don't have a business." In
                  Titan Design we are ready to create any website that you or your business needs. So why choose us?
                  <br />
                  <br />- Simple user-friendly design
                  <br />- Modern technologies
                  <br />- Fully responsible
                  <br />- SEO optimized
                  <br />- Support and maintenance
                  <br />- Guaranteed quality
                </Paragraph>
              </FadeInRight>
            </Box>
          </Box>
        </Container>
      </section>

      <section className="webapps service-item reverse" id="webapps">
        <Container>
          <Box direction="row" className="row" align="center" gap="xlarge" basis="full">
            <Box className="image-wrapper" width="full">
              <StaticImage src="../images/services/webapps.jpg" alt="webapps" placeholder="none" quality={90} />
            </Box>

            <Box width="full">
              <FadeInLeft>
                <h3 className="service-title">Web apps</h3>
                <Paragraph margin={{ vertical: "large" }}>
                  Web applications for a lot of companies are a way to modernize their websites and adapt to users' new
                  expectations. We are here to create capable, reliable, and installable web apps which will help your
                  business grow. Created with modern technologies and easily accessible from anywhere, our tailor-made
                  web applications will give your business the ability to streamline its operations, increase efficiency
                  and reduce costs.
                </Paragraph>
              </FadeInLeft>
            </Box>
          </Box>
        </Container>
      </section>

      <section className="apps service-item" id="apps">
        <Container>
          <Box direction="row" className="row" align="center" gap="xlarge" basis="full">
            <Box className="image-wrapper" width="full">
              <StaticImage src="../images/services/apps.png" alt="mobile apps" placeholder="none" quality={90} />
            </Box>

            <Box width="full">
              <FadeInRight>
                <h3 className="service-title">Mobile apps</h3>
                <Paragraph margin={{ vertical: "large" }}>
                  With the help of mobile applications, you can take your business to a new level: conduct transactions,
                  train employees, plan and set tasks, purchase and deliver goods, etc. In Titan Design we create
                  customized software solutions for mobile platforms like Android or iOS. We develop applications of any
                  complexity, taking into account the needs of your business. Already have a mockup for a new app or
                  starting from scratch? So or otherwise, we will help your idea to come true!
                </Paragraph>
              </FadeInRight>
            </Box>
          </Box>
        </Container>
      </section>

      <section className="cover">
        <div className="text">
          Didn’t find what you are looking for? Not a problem! Just <Route route={CONTACT_PAGE}>contact us</Route> and
          we will think of a custom solution.
        </div>
      </section>

      <section className="ecommerce service-item reverse" id="ecommerce">
        <Container>
          <Box direction="row" className="row" align="center" gap="xlarge" basis="full">
            <Box className="image-wrapper" width="full">
              <StaticImage
                src="../images/services/ecommerce.jpg"
                alt="ecommerce/eshops"
                placeholder="none"
                quality={90}
              />
            </Box>

            <Box width="full">
              <FadeInLeft>
                <h3 className="service-title">E-commerce</h3>
                <Paragraph margin={{ vertical: "large" }}>
                  E-commerce allows you to significantly increase profits since it is a sales generator. However,
                  creating an e-shop requires a detailed study of the company's business processes. E-shops created by
                  Titan Design are developed with performance in mind, fine tuning of out of the box or custom solutions.
                  They also include systems for accepting payments, systems for logistics and warehouse stocks, as well
                  as internal and external accounting integration.
                </Paragraph>
              </FadeInLeft>
            </Box>
          </Box>
        </Container>
      </section>

      <section className="crm service-item" id="crm">
        <Container>
          <Box direction="row" className="row" align="center" gap="xlarge" basis="full">
            <Box className="image-wrapper" width="full">
              <StaticImage src="../images/services/crm.jpg" alt="crm systems" placeholder="none" quality={90} />
            </Box>

            <Box width="full">
              <FadeInRight>
                <h3 className="service-title">CRM systems</h3>
                <Paragraph margin={{ vertical: "large" }}>
                  The creation of a CRM system from scratch is a service that we have been providing for a long time. We
                  provided sites and applications with effective monitoring and control systems, regardless of the
                  subject matter or its complexity. One of our advantages is that we pay attention not only to the
                  functionality, but also to the convenience of CRM’s interface. Therefore, you will be able to quickly
                  master all the features of the program, even if there is a huge number of them.
                </Paragraph>
              </FadeInRight>
            </Box>
          </Box>
        </Container>
      </section>
      <LatestWorksSection />
    </ServicesPage>
  );
};

export default Services;
